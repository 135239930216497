import React, { useState} from "react";
import { FaUserGraduate, FaHistory, FaLaptop, FaProjectDiagram, FaPalette, FaCircle } from 'react-icons/fa';
import Education from "./Education";
import ProgrammingSkills from "./ProgrammingSkills";
import WorkHistory from "./WorkHistory";
import Projects from "./Projects";
import Interests from "./Interests";

const Resume = () => {

    const [isFocusedEd, setIsFocusedEd] = useState(true);
    const [isFocusedPs, setIsFocusedPs] = useState(false);
    const [isFocusedWh, setIsFocusedWh] = useState(false);
    const [isFocusedPj, setIsFocusedPj] = useState(false);
    const [isFocusedIn, setIsFocusedIn] = useState(false);

    const handleFocusEd = () => {
        setIsFocusedEd(true)
        setIsFocusedPs(false)
        setIsFocusedWh(false)
        setIsFocusedPj(false)
        setIsFocusedIn(false)
    };
    const handleFocusPs = () => {
        setIsFocusedEd(false)
        setIsFocusedPs(true)
        setIsFocusedWh(false)
        setIsFocusedPj(false)
        setIsFocusedIn(false)
    };
    const handleFocusWh = () => {
        setIsFocusedEd(false)
        setIsFocusedPs(false)
        setIsFocusedWh(true)
        setIsFocusedPj(false)
        setIsFocusedIn(false)
    };
    const handleFocusPj = () => {
        setIsFocusedEd(false)
        setIsFocusedPs(false)
        setIsFocusedWh(false)
        setIsFocusedPj(true)
        setIsFocusedIn(false)
    };
    const handleFocusIn = () => {
        setIsFocusedEd(false)
        setIsFocusedPs(false)
        setIsFocusedWh(false)
        setIsFocusedPj(false)
        setIsFocusedIn(true)
    };

    return (
        <div name="resume" className="bg-white px-2 py-8">
            <div className="relative w-[90%] mx-auto py-4">
                <div className="mb-8">
                    <h1 className="text-center text-2xl capitalize font-extrabold py-2 mt-4">resume</h1>
                    <p className="text-center text-sm capitalize underline decoration-red-600 underline-offset-8 decoration-2">my formal bio details</p>
                </div>
                <div className="relative bg-white w-full md:w-[90%] mx-auto my-2 flex flex-wrap">
                    <div className="flex flex-row md:basis-[30%] basis-[100%] h-[325px]">
                        <div className="relative bg-primary w-7">
                            <ul className="relative w-7 font-bold mt-[40px]">
                                <li className="py-2.5 my-4 mx-auto bg-primary"> <FaUserGraduate className="mx-auto text-white"/></li>
                                <li className="py-2.5 my-4 mx-auto bg-primary"> <FaHistory className="mx-auto text-white"/></li>
                                <li className="py-2.5 my-4 mx-auto bg-primary"> <FaLaptop className="mx-auto text-white"/></li>
                                <li className="py-2.5 my-4 mx-auto bg-primary"> <FaProjectDiagram className="mx-auto text-white"/></li>
                                <li className="py-2.5 my-4 mx-auto bg-primary"> <FaPalette className="mx-auto text-white"/></li>
                            </ul>  
                        </div>
                        <div className="relative py-6 w-full">
                            <ul className="text-left mr-4 font-bold md:border-r-2 md:border-primary pr-8 md:pr-2 lg:pr-8 text-sm text-primary">
                                <li onClick={handleFocusEd} className={`py-2 my-4 px-4 rounded-r-3xl ${isFocusedEd ? "bg-primary text-white" : ""}`}>Education</li>
                                <li onClick={handleFocusPs} className={`py-2 my-4 px-4 rounded-r-3xl text-ellipsis ${isFocusedPs ? "bg-primary text-white" : ""}`}>Programming Skills</li>
                                <li onClick={handleFocusWh} className={`py-2 my-4 px-4 rounded-r-3xl ${isFocusedWh ? "bg-primary text-white" : ""}`}>Work History</li>
                                <li onClick={handleFocusPj} className={`py-2 my-4 px-4 rounded-r-3xl ${isFocusedPj ? "bg-primary text-white" : ""}`}>Projects</li>
                                <li onClick={handleFocusIn} className={`py-2 my-4 px-4 rounded-r-3xl ${isFocusedIn ? "bg-primary text-white" : ""}`}>Intrests</li>
                            </ul>
                        </div>
                    </div>
                    <div className="relative md:basis-[65%] basis-[100%] h-[325px] mx-auto mt-8 md:mt-0 overflow-y-scroll">
                        <div hidden={!isFocusedEd}>
                            <Education />
                        </div>
                        <div hidden={!isFocusedPs}>
                            <ProgrammingSkills />
                        </div>
                        <div hidden={!isFocusedWh}>
                            <WorkHistory />
                        </div>
                        <div hidden={!isFocusedPj}>
                            <Projects />
                        </div>
                        <div hidden={!isFocusedIn}>
                            <Interests />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Resume;