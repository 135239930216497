import React, { useState, useEffect } from "react";

function Typewriter(props) {
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const totalLoop = props.textArr.length;
      const i = loopNum % totalLoop;
      const fullText = props.textArr[i];

      if (isDeleting) {
        setText(fullText.substring(0, text.length - 1));
      } else {
        setText(fullText.substring(0, text.length + 1));
      }

      if (!isDeleting && text === fullText) {
        setIsDeleting(true);
        setTypingSpeed(props.deleteSpeed);
      } else if (isDeleting && text === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setTypingSpeed(props.typingSpeed);
      }
    }, typingSpeed);

    return () => clearTimeout(timeout);
  }, [text, isDeleting, typingSpeed, loopNum]);

  return <div className="relative h-8 align-center">{text}</div>;
}

export default Typewriter;
