import React from "react";
import { FaCircle } from 'react-icons/fa';
import PercentBar from "../../component/PercentBar";


const Interests = () => {
    return (
        <div name="interests">
            <div className="text-left my-2">
                <p className="min-w-[60%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />Playing Chess</p>
                <p className="ml-12">I find chess to be a highly stimulating and challenging mental activity that requires me to think critically and plan ahead. My involvement in this classic game has honed my strategic thinking skills, allowing me to make quick and effective decisions and anticipate my opponent's moves.</p>
            </div>
            <div className="text-left my-2">
                <p className="min-w-[60%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />Art</p>
                <p className="ml-12">In my free time, I enjoy exploring my artistic side through various forms of art such as painting, drawing, and sculpting. Art is a passion of mine that provides an outlet for my creativity and imagination. It helps me relax, unwind, and escape the demands of daily life.</p>
            </div>
            <div className="text-left my-2">
                <p className="min-w-[60%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />Computer Gaming</p>
                <p className="ml-12">I am also an avid computer gamer, finding excitement and entertainment in the virtual world. I enjoy playing a variety of computer games, including first-person shooters, strategy games, and role-playing games. These games allow me to escape reality and experience different worlds, characters, and scenarios. Gaming has also helped me develop hand-eye coordination, quick reflexes, and problem-solving skills."</p>
            </div>
        </div>
    );
}
export default Interests;