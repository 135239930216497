import React, {useState, useEffect} from "react";
import { FaFacebookF, FaGithub, FaLinkedin, FaInstagram, FaBars, FaPlus } from 'react-icons/fa';
import ProfilePic from '../../assets/Utkarsh.png';
import BgImg from '../../assets/shape-bg.png';
import { Link, animateScroll as scroll } from "react-scroll";
import Typewriter from "../Typewritter";
import CSSLogo from '../../assets/css.svg';
import HTMLLogo from '../../assets/html.svg';
import JSLogo from '../../assets/js.svg';
import TailwindLogo from '../../assets/tailwind.svg';
import ReactLogo from '../../assets/react.svg';
import SpringLogo from '../../assets/spring-3.svg';


const Profile = () => {

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;

        setVisible(prevScrollPos > currentScrollPos);

        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, visible]);

    const [openNav, setOpenNav] = useState(false);

    const handleNav = () => {
        setOpenNav(!openNav);
    }

    const downloadResume = () => {
        window.open('UtkarshResume.pdf', '_blank');
    }

    const textArr = ["Java Developer", "ReactJs Developer", "Full Stack Developer"];

    return (
        <div name="home" className="min-h-screen bg-white">
            <div className={!openNav ? 'hidden' : 'fixed top-0 left-0 h-screen w-screen bg-secondary p-4 z-50'}>
                <div className="absolute top-4 right-4">
                    <FaPlus className="rotate-45 text-white text-2xl text-center" onClick={handleNav}/>
                </div>
                <div className="relative mt-24">
                    <ul className="relative flex flex-col justify-around text-white text-normal">
                        <li className="hover:text-primary text-3xl cursor-pointer py-8"><Link onClick={handleNav} to="home" duration={500} smooth={true} offset={0}>Home</Link></li>
                        <li className="hover:text-primary text-3xl cursor-pointer py-8"><Link onClick={handleNav} to="aboutMe" duration={500} smooth={true} offset={400}>About Me</Link></li>
                        <li className="hover:text-primary text-3xl cursor-pointer py-8"><Link onClick={handleNav} to="portfolio" duration={500} smooth={true} offset={200}>Portfolio</Link></li>
                        <li className="hover:text-primary text-3xl cursor-pointer py-8"><Link onClick={handleNav} to="resume" duration={500} smooth={true} offset={400}>Resume</Link></li>
                        {/* <li className="hover:text-primary text-3xl cursor-pointer py-8"><Link to="contactMe" duration={500} smooth={true} offset={0}>Testimonial</Link></li> */}
                        <li className="hover:text-primary text-3xl cursor-pointer py-8"><Link onClick={handleNav} to="contactMe" duration={500} smooth={true} offset={400}>Contact Me</Link></li>
                    </ul>
                </div>
            </div>
            <div className="bg-primary" hidden={openNav}>
                <div className="relative flex flex-col">
                    <div className={`bg-primary fixed top-0 left-0 w-full z-10 mx-auto py-4 flex flex-row justify-between transition-transform ${visible ? 'transform translate-y-0' : '-translate-y-full'}`}>
                        <div className="">
                            <Link  className="basis-[80%] sm:basis-[50%] flex flex-row mx-8 cursor-pointer" to="home" duration={500} smooth={true} offset={0}>
                                <div className="relative w-[3rem] h-[3rem] bg-red-600 my-auto mx-4 rounded-full text-white font-bold text-[2rem] text-center align-middle">U</div>
                                <h1 className="text-2xl lg:text-3xl py-2 font-bold text-white capitalize">utkarsh <span className="font-normal">goyal</span></h1>
                            </Link>
                        </div>
                        <div className="relative block sm:hidden mt-3">
                            <FaBars className="text-white mr-4 text-2xl align-middle" onClick={handleNav}/>
                        </div>
                        <div className="hidden sm:block relative basis-[35%] my-auto pr-8">
                            <ul className="relative flex flex-row text-white justify-around">
                                <li><Link className="hover:text-red-600 py-2 cursor-pointer" to="home" duration={500} smooth={true} offset={0}>Home</Link></li>
                                <li><Link className="hover:text-red-600 py-2 cursor-pointer" to="aboutMe" duration={500} smooth={true} offset={0}>About Me</Link></li>
                                <li><Link className="hover:text-red-600 py-2 cursor-pointer" to="portfolio" duration={500} smooth={true} offset={-200}>Portfolio</Link></li>
                                <li><Link className="hover:text-red-600 py-2 cursor-pointer" to="resume" duration={500} smooth={true} offset={0}>Resume</Link></li>
                                {/* <li className="hover:text-red-600 cursor-pointer">Testimonial</li> */}
                                <li><Link className="hover:text-red-600 py-2 cursor-pointer" to="contactMe" duration={500} smooth={true} offset={0}>Contact Me</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="relative flex flex-col-reverse sm:flex-row mt-20 py-4">
                        <div className="relative flex flex-col text-white basis-1/2 mx-auto my-auto py-4">
                            <div className="relative flex flex-row justify-center mx-auto w-[40%] px-2 py-4">
                                <a href="https://www.linkedin.com/in/utkarsh-goyal-2b3613168/" target="_blank"><FaLinkedin className="mx-2 text-2xl cursor-pointer hover:text-red-400 transition-all duration-300"/></a>
                                <a href="https://github.com/utkarshgoyal5" target="_blank"><FaGithub className="mx-2 text-2xl cursor-pointer hover:text-red-400 transition-all duration-300"/></a>
                                <a href="https://www.instagram.com/utkarsh_goyal_/" target="_blank"><FaInstagram className="mx-2 text-2xl cursor-pointer hover:text-red-400 transition-all duration-300"/></a>
                                <a href="https://www.facebook.com/utkarsh.goyal.750/" target="_blank"><FaFacebookF className="mx-2 text-2xl cursor-pointer hover:text-red-400 transition-all duration-300"/> </a>
                            </div>
                            <h2 className="my-4 text-2xl">Hello I'm <span className="font-semibold text-3xl text-red-600">Utkarsh Goyal</span></h2>
                            <h1 className="font-normal text-center text-3xl my-4"><Typewriter textArr={textArr} typingSpeed={150} deleteSpeed={100} /></h1>
                            <p className="relative text-lg font-extralight my-4">Crafting Websites with Both Looks and Brains!</p>
                            <div>
                                <div className="flex flex-col sm:flex-row justify-center my-8">
                                    <div className="relative my-auto py-2 text-center pr-4 sm:border-r-2 font-light">
                                        <span className="inline-block align-middle border-b-2 sm:border-b-0 text-left">Tech Stack</span>
                                    </div>
                                    <ul className="flex flex-wrap justify-center pl-2 my-4">
                                        <li className="relative w-[3.5rem] h-[3.5rem] mx-2 my-2 bg-white rounded-full shadow-sm shadow-white">
                                            <a href="https://html.spec.whatwg.org/" target="_blank"><img className="p-3" src={HTMLLogo} title="HTML5" alt="skill-icon"/></a>
                                        </li>
                                        <li className="relative w-[3.5rem] h-[3.5rem] mx-2 my-2 bg-white rounded-full shadow-sm shadow-white">
                                            <a href="https://www.w3.org/Style/CSS/Overview.en.html" target="_blank"><img className="p-3" src={CSSLogo} title="CSS3" alt="skill-icon" /></a>
                                        </li>
                                        <li className="relative w-[3.5rem] h-[3.5rem] mx-2 my-2 bg-white rounded-full shadow-sm shadow-white">
                                            <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide" target="_blank"><img className="p-3" src={JSLogo} title="Java Script" alt="skill-icon" /></a>
                                        </li>
                                        <li className="relative w-[3.5rem] h-[3.5rem] mx-2 my-2 bg-white rounded-full shadow-sm shadow-white">
                                            <a href="https://reactjs.org/" target="_blank"><img className="p-3" src={ReactLogo} title="React" alt="skill-icon" /></a>
                                        </li>
                                        <li className="relative w-[3.5rem] h-[3.5rem] mx-2 my-2 bg-white rounded-full shadow-sm shadow-white">
                                            <a href="https://tailwindcss.com/" target="_blank"><img className="p-3 my-1.5" src={TailwindLogo} title="Tailwind CSS" alt="skill-icon" /></a>
                                        </li>
                                        <li className="relative w-[3.5rem] h-[3.5rem] mx-2 my-2 bg-white rounded-full shadow-sm shadow-white">
                                            <a href="https://spring.io/projects/spring-boot" target="_blank"><img className="p-3" src={SpringLogo} title="Spring Boot" alt="skill-icon" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="relative flex flex-row mx-auto pb-12 pt-4 w-[80%] justify-center">
                                <Link to="contactMe" duration={500} smooth={true} offset={0}><button className="hover:transition-all hover:duration-700 rounded-full hover:bg-white hover:text-primary border-2 px-12 py-2 mx-4">Hire Me</button></Link>
                                <button onClick={downloadResume} className="hover:transition-all hover:duration-700 rounded-full border-2 border-red-600 hover:bg-red-600 px-8 py-2 mx-4">Get Resume</button>
                            </div>
                        </div>
                        <div className="relative basis-1/2 mx-auto my-auto">
                            <div className="relative rounded-full sm:w-96 w-80 m-auto ring-offset-2 ring-0 hover:ring-offset-0 hover:transition-all hover:duration-700">
                                <img className="rounded-full w-full p-2 hover:p-0 hover:transition-all hover:duration-700 m-auto" src={ProfilePic} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative bg-primary">
                <img className="relative w-full" src={BgImg} />
            </div>
        
        </div>
    )
}

export default Profile;