// import { stringify } from "postcss";
import React, { useEffect } from "react";
import { FaCircle } from 'react-icons/fa';

const PercentBar = (props) => {
   
    return (
        <div className="text-left p-2">
            <p className="text-red-600 py-2"><FaCircle className='inline mx-2 mr-6' />{props.title}</p>
            <div className="flex fle-row h-[20px] w-[80%] ml-12 bg-yellow-400">
                <div className={`bg-primary ${props.percent}`}></div>
            </div>
        </div>
    );
}

export default PercentBar;