import React from "react";
import { FaCircle } from 'react-icons/fa';
import PercentBar from "../../component/PercentBar";


const ProgrammingSkills = () => {

    const props = [
        {
            title: 'Java Core',
            percent: 'basis-[90%]'
        },
        {
            title: 'Spring Boot',
            percent: 'basis-[80%]'
        },
        {
            title: 'MySQL',
            percent: 'basis-[70%]'
        },
        {
            title: 'Linux',
            percent: 'basis-[70%]'
        },
        {
            title: 'React Js',
            percent: 'basis-[60%]'
        },
        {
            title: 'Git',
            percent: 'basis-[60%]'
        },
        {
            title: 'C++',
            percent: 'basis-[50%]'
        },
        {
            title: 'Flutter',
            percent: 'basis-[30%]'
        },    
    ]

    // const pct = '10%'

    return (
        <div name="programmingSkills">
            <div className="p-2 grid grid-cols-1 md:grid-cols-2">
                {props.map((prop, i) => (
                    <PercentBar {...prop} />
                ))}
            </div>
            
        </div>
    );
}

export default ProgrammingSkills;