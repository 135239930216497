import React, { useState, useRef } from "react";
import { FaFacebookF, FaGithub, FaLinkedin, FaInstagram, FaPaperPlane } from 'react-icons/fa';
import Mails from'../../assets/mailz.jpeg';
import emailjs from '@emailjs/browser';


const ContactMe = () => {

    const form = useRef();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
      });
    
      const handleChange = event => {
        setFormData({...formData, [event.target.name]: event.target.value});
      };
    
      const handleSubmit = event => {

        event.preventDefault();
        emailjs.sendForm('service_mm3412q', 'template_26dr9od', form.current, '49iZ9vY5ogZnm-IxS')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        setFormData({
            name: "",
            email: "",
            message: "",
        });
      };

    return (
        <div name="contactMe" className="bg-white min-h-screen p-2">
            <div className="relative w-[90%] md:w-[80%] mx-auto py-4">
                <div className="mb-8">
                    <h1 className="text-center text-2xl capitalize font-extrabold py-2 mt-4">contact me</h1>
                    <p className="text-center text-sm capitalize underline decoration-red-600 underline-offset-8 decoration-2">lets keep in touch</p>
                </div>
                <div className="relative grid grid-cols-1 md:grid-cols-2 bg-primary mx-auto shadow-xl">
                    <div className="relative m-8">
                        <div className="hidden md:block absolute w-full opacity-50 bottom-12">
                            <p className="capitalize text-white text-lg text-left py-2">send your mail here</p>
                            <img className=" w-full" src={Mails} />
                        </div>
                        <h1 className="text-white text-4xl font-bold text-left p-2 my-2 capitalize text-center md:text-left">get in touch</h1>
                        <div className="relative flex flex-row justify-center md:justify-start text-white text-xl my-2">
                            <a href="https://www.linkedin.com/in/utkarsh-goyal-2b3613168/" target="_blank"><FaLinkedin className="mx-2 cursor-pointer hover:text-red-400  transition-all duration-300"/></a>
                            <a href="https://github.com/utkarshgoyal5" target="_blank"><FaGithub className="mx-2 cursor-pointer hover:text-red-400 transition-all duration-300"/></a>
                            <a href="https://www.instagram.com/utkarsh_goyal_/" target="_blank"><FaInstagram className="mx-2 cursor-pointer hover:text-red-400 transition-all duration-300"/></a>
                            <a href="https://www.facebook.com/utkarsh.goyal.750/" target="_blank"><FaFacebookF className="mx-2 cursor-pointer hover:text-red-400 transition-all duration-300"/></a>
                        </div>
                        
                    </div>
                    <div>
                        <form ref={form} onSubmit={handleSubmit} className='m-8 p-4 bg-gray-100 rounded-lg text-left'>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 font-medium mb-2"
                                    htmlFor="name"
                                >
                                    Name
                                </label>
                                <input
                                    className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 font-medium mb-2"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 font-medium mb-2"
                                    htmlFor="message"
                                >
                                    Message
                                </label>
                                <textarea
                                    className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                                    id="message"
                                    type="text"
                                    name="message"
                                    rows="4"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button
                                className="bg-red-500 text-lg font-bold text-white hover:bg-red-300  transition-all duration-300  w-full font-medium py-2 px-4 rounded-lg"
                                type="submit"
                                >
                            Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ContactMe;