import React from "react";
import AboutMe from "./portfolioContainer/aboutMe/AboutMe";
import ContactMe from "./portfolioContainer/contactMe/ContactMe";
import Profile from "./portfolioContainer/home/Profile";
import Resume from "./portfolioContainer/resume/Resume";
import Testimonial from "./portfolioContainer/testimonial/Testimonial";
import Portfolio from "./portfolioContainer/portfolio/Portfolio";

 const App = () => {
  
  return (
    <div className="text-center bg-gray-100">
        <Profile />
        <AboutMe />
        <Resume />
        <Portfolio />
        {/* <Testimonial /> */}
        <ContactMe />
    </div>
  )
}
export default App;