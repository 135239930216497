import React from "react";
import { FaCircle } from 'react-icons/fa';
import PercentBar from "../../component/PercentBar";


const Projects = () => {
    return (
        <div name="projects">
            <div className="py-2">
                <div className="flex flex-row text-left text-lg">
                    <p className="min-w-[70%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />My Portfolio</p>
                    <div className="p-1">
                        <p className="bg-yellow-400 text-white text-sm text-center rounded-3xl py-2 px-4 mx-auto">Feb 23</p>
                    </div>
                </div>
                <div className="text-left ml-12">
                    <h1 className="font-bold">Technology Used: ReactJs, Tailwind, Parcel, EmailEj, Firebase</h1>
                    <p className="mt-1">A personal porfolio website to showcase all my details and projects at one place</p>
                </div>
            </div>
            <div className="py-2">
                <div className="flex flex-row text-left text-lg">
                    <p className="min-w-[70%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />Baigos</p>
                    <div className="p-1">
                        <p className="bg-yellow-400 text-white text-sm text-center rounded-3xl py-2 px-4 mx-auto">Dec 22</p>
                    </div>                
                </div>
                <div className="text-left ml-12">
                    <h1 className="font-bold">Technology Used: SpringBoot, ReactJs, Tailwind, AWS</h1>
                    <p className="mt-1">Baigos is a website used for renting and lending space for goods. It can be used to store furniture, appliances, or any other type of goods. </p>
                </div>
            </div>
            <div className="py-2">
                <div className="flex flex-row text-left text-lg">
                    <p className="min-w-[70%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />MyChatBot</p>
                    <div className="p-1">
                        <p className="bg-yellow-400 text-white text-sm text-center rounded-3xl py-2 px-4 mx-auto">Aug 23</p>
                    </div>              
                </div>
                <div className="text-left ml-12">
                    <h1 className="font-bold">ChatGPT APIs, ReactJs, Vite, React chat UI kit</h1>
                    <p className="mt-1">Crafted a dynamic chatbot using ChatGPT APIs and JavaScript to engage users in natural conversations and provide instant assistance.</p>
                </div>
            </div>
        </div>
    );
}
export default Projects;