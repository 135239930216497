import React from "react";
import { FaCircle } from 'react-icons/fa';
import PercentBar from "../../component/PercentBar";


const WorkHistory = () => {
    return (
        <div name="workHistory" className="m-4">
            <div className="flex flex-row text-left text-lg">
                <p className="min-w-[60%] text-red-600 py-2 my-auto"><FaCircle className='inline mx-2 mr-6' />BlackNGreen</p>
                <div className="bg-yellow-400 text-white text-center rounded-3xl mx-auto px-4 py-2 my-2 text-sm">Oct'2021-Present</div>
            </div>
            <div className="text-left pl-12">
                <h2 className="uppercase font-bold py-1">Full Stack Developer</h2>
                <p></p>
                <ul className="my-2">
                    <li className='py-1'>- Full Stack Developer with experience in designing, developing, and integrating complex modules</li>
                    <li className='py-1'>- Developed Wallet Module from scratch using Spring Boot and integrated multiple RESTful APIs</li>
                    <li className='py-1'>- Implemented features such as coin distribution and auditing</li>
                    <li className='py-1'>- Developed and integrated multiple RESTful APIs for Payment Gateway interacting with internal modules and telecom operators using various formats</li>
                    <li className='py-1'>- Extensive experience in technologies such as Spring Boot, React js, JPA, Lombok, JDBC, ActiveMQ, Log4j2, Tomcat, Mysql-connector, Swagger, war & jar files etc</li>
                    <li className='py-1'>- My work on the system configuration GUI project using React js, tailwind, react-router-dom, parcel, and antd demonstrates my ability to work on front-end projects.</li>
                </ul>
            </div>
        </div>
    );
}
export default WorkHistory;