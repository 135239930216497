import React from "react";
import { FaCircle } from 'react-icons/fa';


const Education = () => {

    return (
        <div name="education" >
            <div className="py-2">
                <div className="flex flex-row text-center sm:text-left text-lg">
                    <p className="w-[75%] mx-2 text-red-600 py-2 my-auto"><FaCircle className='hidden sm:inline mx-2 mr-4' />Ajay Kumar Garg Engineering College, Ghaziabad</p>
                    <div className="p-2 mx-auto mt-1">
                        <p className="bg-yellow-400 text-white text-sm text-center rounded-3xl py-1 px-2 mx-auto">2017-21</p>
                    </div>                
                </div>
                <div className="sm:text-left text-center sm:ml-8 px-4 mx-auto">
                    <p className="uppercase py-2">batchlor of technology, information technology</p>
                </div>
            </div>
            <div className="py-2">
                <div className="flex flex-row text-center sm:text-left text-lg">
                    <p className="w-[75%] mx-2 text-red-600 py-2 my-auto"><FaCircle className='hidden sm:inline mx-2 mr-4' />Delhi Public School, Aligarh</p>
                    <div className="p-2 mx-auto mt-1">
                        <p className="bg-yellow-400 text-white text-sm text-center rounded-3xl py-1 px-2 mx-auto">2016-17</p>
                    </div>                
                </div>
                <div className="sm:text-left text-center sm:ml-8 px-4 mx-auto">
                    <p className="uppercase py-2">secondary qualification</p>
                </div>
                {/* <div className="flex flex-row text-center sm:text-left text-lg">
                    <p className="min-w-[80%] text-red-600 py-2 my-auto"><FaCircle className='hidden sm:inline mx-2 mr-6' />Delhi Public School, Aligarh</p>
                    <div className="p-1 mx-auto mt-1">
                        <p className="bg-red-600 text-white text-sm text-center rounded-3xl py-1 px-4 mx-auto">2016-17</p>
                    </div>                
                </div>
                <div className="text-left ml-12">
                    <p className="uppercase py-2">secondary qualification</p>
                </div> */}
            </div>
            <div className="py-2">
                <div className="flex flex-row text-center sm:text-left text-lg">
                    <p className="w-[75%] mx-2 text-red-600 py-2 my-auto"><FaCircle className='hidden sm:inline mx-2 mr-4' />Our Lady Of Fatima Hr. Secondary School, Aligarh</p>
                    <div className="p-2 mx-auto mt-1">
                        <p className="bg-yellow-400 text-white text-sm text-center rounded-3xl py-1 px-2 mx-auto">2014-15</p>
                    </div>                
                </div>
                <div className="sm:text-left text-center sm:ml-8 px-4 mx-auto">
                    <p className="uppercase py-2">higher secondary qualification</p>
                </div>
                {/* <div className="flex flex-row text-center sm:text-left text-lg">
                    <p className="min-w-[80%] text-red-600 py-2 my-auto"><FaCircle className='hidden sm:inline mx-2 mr-6' />Our Lady Of Fatima Hr. Secondary School, Aligarh</p>
                    <div className="p-1 mx-auto mt-1">
                        <p className="bg-red-600 text-white text-sm text-center rounded-3xl py-1 px-4 mx-auto">2014-15</p>
                    </div>                
                </div>
                <div className="text-left ml-12">
                    <p className="uppercase py-2">higher secondary qualification</p>
                </div> */}
            </div>
        </div>
    );
}

export default Education;