import React, { useState } from "react";
import AboutImg from'../../assets/about-img.webp';
import { FaCircle } from 'react-icons/fa';
import { Link, animateScroll as scroll } from "react-scroll";

const AboutMe = () => {

    const handleDownload = () => {
        window.open('UtkarshResume.pdf', '_blank');
    };

    return (
        <div name="aboutMe" className="bg-white min-h-screen p-2">
            <div className="relative w-[90%] mx-auto py-4 mt-8">
            <div className="mb-8">
                    <h1 className="text-center text-2xl capitalize font-extrabold py-2 mt-4">about me</h1>
                    <p className="text-center text-sm capitalize underline decoration-red-600 underline-offset-8 decoration-2">  why choose me?  </p>
                </div>
                <div className="relative bg-white w-full md:w-[90%] mx-auto my-4 grid grid-cols-1 md:grid-cols-2">
                <div className="relative m-auto p-12 aspect-w-16 aspect-h-9">
                    <img className="rounded-[1.6rem]" src={AboutImg} alt="About" />
                </div>

                    <div className="relative p-8 my-auto font-medium text-neutral-500">
                        <p className="text-justify">
                            I'm a passionate full stack developer experienced in ReactJS, Spring Boot, HTML, CSS, and JavaScript. I thrive on solving complex challenges and delivering captivating web applications. With a keen eye for clean code, I create dynamic user interfaces that leave a lasting impression. I navigate Linux environments with ease, optimizing workflows for smooth deployment. Agile methodologies drive my development process, ensuring collaboration and iterative improvements. Continuously learning and staying up to date with the latest technologies, I'm always seeking innovative ways to push boundaries and deliver cutting-edge solutions. If you're ready to embark on an extraordinary coding adventure, let's connect and bring your ideas to life!                        
                        </p>
                    </div>
                </div>
                {/* <div className="relative w-full md:w-[70%] mx-auto">                
                    <div className="relative flex flex-wrap mx-auto py-6 w-full justify-center">
                        <Link to="contactMe" duration={500} smooth={true} offset={0}><button className="hover:transition-all hover:duration-300 rounded-full bg-gray-900 hover:bg-gray-600 text-white font-extrabold px-4 py-2 my-2 mx-8">Hire Me</button></Link>
                        <button onClick={handleDownload} className="hover:transition-all hover:duration-300 rounded-full bg-red-600 hover:bg-red-300 text-white font-extrabold px-4 py-2 my-2 mx-8">Get Resume</button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default AboutMe;